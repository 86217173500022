import React, { useCallback, useState } from 'react';
import useFormSubmit from '../../lib/SubmitForm';
import FormMessages from './parts/FormMessages';
import SubmitButton from './parts/SubmitButton';

const TrialForm = () => {
    const { submitForm } = useFormSubmit();
    const [fullname, setFullname] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [employees, setEmployees] = useState('');
    const [message, setMessage] = useState('');
    const [industry, setIndustry] = useState('');
    const [errors, setErrors] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const performSubmit = useCallback(async () => {
        setIsSubmitting(true);

        const data = {
            form_name: 'Trial Request',
            fields: [
                { label: 'Name', value: fullname },
                { label: 'Company Name', value: companyName },
                { label: 'Email', value: email },
                { label: 'Phone', value: phone },
                { label: 'Employees', value: employees },
                { label: 'Industry', value: industry },
                { label: 'Message', value: message },
            ],
        };

        const formData = new FormData();
        formData.append('data', JSON.stringify(data));

        const result = await submitForm(formData);

        setErrors([...result?.errors]);
        setIsSubmitted(result?.isSubmitted);

        setIsSubmitting(false);
    }, [companyName, email, employees, fullname, industry, message, phone, submitForm]);

    const handleSubmit = (e) => {
        e.preventDefault();
        performSubmit();
    };

    return (
        <>
            <hr className="mb-4" />
            <form onSubmit={handleSubmit} action="#" method="post" className="row contact-form">
                <FormMessages isSubmitted={isSubmitted} errors={errors} />

                <div className="col-md-6">
                    <input
                        value={fullname}
                        required
                        onChange={(e) => setFullname(e.target.value)}
                        type="text"
                        placeholder="Full Name"
                    />
                </div>
                <div className="col-md-6">
                    <input
                        value={companyName}
                        required
                        onChange={(e) => setCompanyName(e.target.value)}
                        type="text"
                        placeholder="Company Name"
                    />
                </div>
                <div className="col-md-6">
                    <input
                        value={email}
                        required
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                        placeholder="Email Address"
                    />
                </div>
                <div className="col-md-6">
                    <input
                        type="text"
                        required
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        placeholder="Phone Number"
                    />
                </div>
                <div className="col-md-6">
                    <select
                        value={employees}
                        onChange={(e) => setEmployees(e.target.value)}
                        onBlur={(e) => setEmployees(e.target.value)}
                        required
                    >
                        <option value="">Company Size ?</option>
                        <option value="1-10">1-10 Employees</option>
                        <option value="20-50">20-50 Employees</option>
                        <option value="100-200">100-200 Employees</option>
                        <option value="500+">500+ Employees</option>
                    </select>
                </div>

                <div className="col-md-6">
                    <select
                        required
                        value={industry}
                        onChange={(e) => setIndustry(e.target.value)}
                        onBlur={(e) => setIndustry(e.target.value)}
                    >
                        <option value="">Industry ?</option>
                        <option value="Hospitality">Hospitality</option>
                        <option value="Retail Business">Retail Business</option>
                        <option value="Manufacturing">Manufacturing</option>
                        <option value="Manufacturing">Wholesale</option>
                        <option value="Manufacturing">Other</option>
                    </select>
                </div>

                <div className="col-md-12">
                    <textarea
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder="Current Software ? Additional Notes / Questions ?"
                    ></textarea>
                </div>

                <div className="col-md-12 text-center">
                    <SubmitButton label="Register Now !" isSubmitting={isSubmitting} />
                </div>
            </form>
        </>
    );
};

export default TrialForm;
