const FormMessages = ({ errors, isSubmitted }) => (
    <>
        {errors.length > 0 && (
            <div className="col-md-12">
                {errors?.map((error, index) => (
                    <p className="text-danger mb-3" key={`${index + 5}-error`}>
                        <b>Error:</b> {error}
                    </p>
                ))}
            </div>
        )}

        {isSubmitted && (
            <div className="col-md-12 mb-3">
                <span className="bg-success w-100 p-3 text-white">
                    Thank you for choosing us! We appreciate your business and will be in touch
                    shortly
                </span>
            </div>
        )}
    </>
);

export default FormMessages;
