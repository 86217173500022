import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

function useFormSubmit() {
    let errors = [];
    let isSubmitted = false;

    const { executeRecaptcha } = useGoogleReCaptcha();

    const submitForm = async (formData) => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return {};
        }

        const token = await executeRecaptcha('FORMSUBMITTION');

        formData.append('recaptcha_token', `${token}`);
        formData.append('submit', 'true');

        try {
            const response = await fetch('https://oto.rw/form-handler/index.php', {
                method: 'POST',
                body: formData,
            });

            if (response?.ok) {
                const responseData = await response.json();

                if (Array.isArray(responseData?.errors)) {
                    errors = [...responseData.errors];
                }

                isSubmitted = responseData?.success;
            }
        } catch (e) {
            console.log(e);
            errors = ['Server connection failed'];
        }

        return { errors, isSubmitted };
    };

    return { submitForm };
}

export default useFormSubmit;
