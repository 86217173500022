import SignUpForm from '../Forms/SignUpForm';
import BaseModal from './BaseModal';

const RegisterModal = ({ onRequestClose, show }) => (
    <BaseModal
        subtitle="Revolutionize your business with our OTO Software subscription. Sign up now."
        show={show}
        onRequestClose={onRequestClose}
        title="Registration"
    >
        <SignUpForm />
    </BaseModal>
);

export default RegisterModal;
