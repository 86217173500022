import { useEffect, useState } from 'react';

const BaseModal = ({ children, title, subtitle, show, onRequestClose }) => {
    const animationDuration = 400;
    const baseCssClass = 'bs-modal';
    const [cssClass, setCssClass] = useState(baseCssClass);
    const bodyElt = document.querySelector('body');

    const handleClose = () => {
        setCssClass(baseCssClass);

        if (bodyElt) {
            bodyElt.classList.remove('modal-open');
        }

        setTimeout(onRequestClose, animationDuration);
    };

    useEffect(() => {
        if (show) {
            if (bodyElt) {
                bodyElt.classList.add('modal-open');
            }
            setCssClass(`${baseCssClass} ${baseCssClass}--show`);
        }
    }, [bodyElt, show]);

    return (
        <div className={cssClass}>
            <div className="bs-modal__body">
                <div className="bs-modal__header">
                    <div>
                        <h4>{title}</h4>
                        <p>{subtitle}</p>
                    </div>

                    <span>
                        <button onClick={handleClose} type="button" className="btn btn-danger">
                            ⛌
                        </button>
                    </span>
                </div>
                <div className="bs-modal__content">{children}</div>
            </div>
        </div>
    );
};

export default BaseModal;
