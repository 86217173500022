import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import miniLogo from '../../assets/images/oto-min-logo.png';
import RegisterModal from '../Modals/RegisterModal';
import TrialModal from '../Modals/TrialModal';

function Drawer({ drawer, action }) {
    const [itemSize, setSize] = useState('0px');
    const [item, setItem] = useState('home');
    const [showRegisterModal, setShowRegisterModal] = useState(false);
    const [showTrialModal, setShowTrialModal] = useState(false);

    const handler = (e, value) => {
        // e.preventDefault();
        const getItems = document.querySelectorAll(`#${value} li`).length;
        if (getItems > 0) {
            setSize(`${43 * getItems}px`);
            setItem(value);
        }
    };
    return (
        <>
            {showRegisterModal && (
                <RegisterModal
                    show={showRegisterModal}
                    onRequestClose={() => setShowRegisterModal(false)}
                />
            )}

            {showTrialModal && (
                <TrialModal show={showTrialModal} onRequestClose={() => setShowTrialModal(false)} />
            )}

            <div
                onClick={(e) => action(e)}
                className={`off_canvars_overlay ${drawer ? 'active' : ''}`}
            ></div>
            <div className="offcanvas_menu">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className={`offcanvas_menu_wrapper ${drawer ? 'active' : ''}`}>
                                <div className="canvas_close">
                                    <a href="#" onClick={(e) => action(e)}>
                                        <i className="fa fa-times"></i>
                                    </a>
                                </div>
                                <div id="menu" className="text-left ">
                                    <p className="mb-3 text-center">
                                        <img width={30} src={miniLogo} alt="OTO Software" />
                                    </p>

                                    <ul className="offcanvas_main_menu">
                                        <li
                                            onClick={(e) => handler(e, 'home')}
                                            id="home"
                                            className="menu-item-has-children active"
                                        >
                                            <a href="/">Home</a>
                                        </li>
                                        <li
                                            onClick={(e) => handler(e, 'about')}
                                            id="about"
                                            className=""
                                        >
                                            <a href="/about">About</a>
                                        </li>

                                        <li
                                            onClick={(e) => handler(e, 'products')}
                                            id="products"
                                            className="menu-item-has-children active"
                                        >
                                            <span className="menu-expand">
                                                <i className="fa fa-angle-down"></i>
                                            </span>
                                            <a href="#">Apps</a>
                                            <ul
                                                className="sub-menu"
                                                style={{
                                                    height: item === 'products' ? itemSize : '0px',
                                                }}
                                            >
                                                <li>
                                                    <Link title="OTO POS Software" to="/apps/pos">
                                                        POS
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        title="OTO Frontesk, Hotel Management"
                                                        to="/apps/frontdesk"
                                                    >
                                                        Frontdesk
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        title="OTO Inventory Management"
                                                        to="/apps/inventory-management"
                                                    >
                                                        Inventory
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        title="OTO Backoffice"
                                                        to="/apps/backoffice"
                                                    >
                                                        Backoffice
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li
                                            onClick={(e) => handler(e, 'contact')}
                                            id="contact"
                                            className="menu-item-has-children active"
                                        >
                                            <Link title="Contact US" to="/partnership">
                                                Partnership
                                            </Link>
                                            <Link title="Contact US" to="/contact">
                                                Contact
                                            </Link>
                                        </li>
                                    </ul>
                                </div>

                                <div className="mt-3 d-flex justify-content-between">
                                    <a
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setShowRegisterModal(true);
                                        }}
                                        className="btn btn-sm btn-light mt-2"
                                        href="#"
                                    >
                                        <i className="fas fa-user-circle text-primary"></i> Sign Up
                                        Now !
                                    </a>
                                    <a
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setShowTrialModal(true);
                                        }}
                                        className="btn btn-sm btn-light mt-2"
                                        href="#"
                                    >
                                        <i className="fal text-primary fa-layer-group"></i> Free
                                        Trial
                                    </a>
                                </div>
                                <div className="offcanvas-social">
                                    <ul className="text-left">
                                        <li>
                                            <a href="https://facebook.com/kapprw">
                                                <i className="fab fa-facebook-f"></i>
                                            </a>
                                        </li>

                                        <li>
                                            <a href="https://www.instagram.com/kapp.rw/">
                                                <i className="fab fa-instagram"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://linkedin.com/company/kapprw">
                                                <i className="fab fa-linkedin"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Drawer;
