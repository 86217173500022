const SubmitButton = ({ label, isSubmitting }) => (
    <input
        type="submit"
        name="submit"
        disabled={isSubmitting}
        value={isSubmitting ? `${label} (...)` : label}
    />
);

export default SubmitButton;
