/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/kapp-logo.png';
import getContactInfo from '../../lib/GetContactInfo';
import ProjectHomeThree from '../ProjectBanner/ProjectHomeThree';

function FooterHomeThree({ className }) {
    return (
        <>
            <section className={`appie-footer-area appie-footer-3-area ${className}`}>
                <ProjectHomeThree />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-6">
                            <div className="footer-about-widget footer-about-widget-3">
                                <div className="logo mx-auto mx-md-0 mx-lg-0">
                                    <img
                                        width={40}
                                        src={logo}
                                        alt="Kapp Logo"
                                        title="Kapp Microsystems"
                                    />
                                </div>
                                <p className="mb-0 pb-0">
                                    <a href="https://kapp.rw" target="_blank">
                                        Kapp
                                    </a>{' '}
                                    is a leading provider of cutting-edge software solutions for
                                    businesses of all sizes. Since 2015, the company has built a
                                    reputation for delivering innovative and reliable software
                                    products. One of its flagship products is OTO, a powerful
                                    business software.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="footer-navigation footer-navigation-3">
                                <h4 className="title">Apps</h4>
                                <ul>
                                    <li>
                                        <Link to="/apps/backoffice">
                                            <i className="fal fa-arrow-right mr-2"></i>Backoffice
                                        </Link>
                                    </li>
                                    <li>
                                        <Link title="OTO POS Software" to="/apps/pos">
                                            <i className="fal fa-arrow-right mr-2"></i>POS
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            title="OTO Inventory Management"
                                            to="/apps/inventory-management"
                                        >
                                            <i className="fal fa-arrow-right mr-2"></i>Inventory
                                            Management
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            title="OTO Frontdesk - Hotel management Software"
                                            to="/apps/frontdesk"
                                        >
                                            <i className="fal fa-arrow-right mr-2"></i>Frontdesk
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                            <div className="footer-widget-info">
                                <h4 className="title">Get In Touch</h4>
                                <ul className="contact-items">
                                    <li>
                                        <a href={`mailto:${getContactInfo().email1}`}>
                                            <i className="fal fa-envelope" />{' '}
                                            <span>{getContactInfo().email1}</span>
                                            <span className="mx-1">/</span>
                                            <span>{getContactInfo().email2}</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="tel:+250 788 383 590">
                                            <i className="fal fa-phone" />{' '}
                                            <span>+(250) 788 381 832</span>
                                            <span>+(250) 788 383 590</span>
                                        </a>
                                    </li>
                                </ul>

                                <div className="social mt-10">
                                    <p className="mb-2 text-gray">Follow us on social media</p>
                                    <ul>
                                        <li>
                                            <a
                                                rel="noreferrer"
                                                target="_blank"
                                                href="https://facebook.com/kapprw"
                                            >
                                                <i className="fab fa-facebook-f" />
                                            </a>
                                        </li>

                                        <li>
                                            <a
                                                rel="noreferrer"
                                                target="_blank"
                                                href="https://instagram.com/kapp.rw"
                                            >
                                                <i className="fab fa-instagram" />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                rel="noreferrer"
                                                target="_blank"
                                                href="https://linkedin.com/company/kapprw"
                                            >
                                                <i className="fab fa-linkedin-in" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div
                                className="
                footer-copyright
                d-flex
                align-items-center
                justify-content-between
                pt-35
              "
                            >
                                <div className="apps-download-btn">
                                    <ul>
                                        <li>
                                            <Link className="item-2" to="/contact">
                                                <i className="fa fa-comments-alt" /> Feedback
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="copyright-text">
                                    <p>Copyright © 2024 Kapp. All rights reserved.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default FooterHomeThree;
