import React, { useCallback, useState } from 'react';
import useFormSubmit from '../../lib/SubmitForm';
import FormMessages from './parts/FormMessages';
import SubmitButton from './parts/SubmitButton';

const SignUpForm = () => {
    const { submitForm } = useFormSubmit();
    const [fullname, setFullname] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const performSubmit = useCallback(async () => {
        setIsSubmitting(true);

        const data = {
            form_name: 'SignUp',
            fields: [
                { label: 'Name', value: fullname },
                { label: 'Company Name', value: companyName },
                { label: 'Email', value: email },
                { label: 'Phone', value: phone },
                { label: 'Message', value: message },
            ],
        };

        const formData = new FormData();
        formData.append('data', JSON.stringify(data));

        const result = await submitForm(formData);

        setErrors([...result?.errors]);
        setIsSubmitted(result?.isSubmitted);

        setIsSubmitting(false);
    }, [companyName, email, fullname, message, phone, submitForm]);

    const handleSubmit = (e) => {
        e.preventDefault();
        performSubmit();
    };

    return (
        <form onSubmit={handleSubmit} action="#" method="post" className="row contact-form">
            <FormMessages isSubmitted={isSubmitted} errors={errors} />

            <div className="col-md-6">
                <input
                    value={fullname}
                    required
                    onChange={(e) => setFullname(e.target.value)}
                    type="text"
                    placeholder="Full Name"
                />
            </div>
            <div className="col-md-6">
                <input
                    value={companyName}
                    required
                    onChange={(e) => setCompanyName(e.target.value)}
                    type="text"
                    placeholder="Company Name"
                />
            </div>
            <div className="col-md-6">
                <input
                    value={email}
                    required
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    placeholder="Email Address"
                />
            </div>
            <div className="col-md-6">
                <input
                    type="text"
                    value={phone}
                    required
                    onChange={(e) => setPhone(e.target.value)}
                    placeholder="Phone Number"
                />
            </div>

            <div className="col-md-12">
                <textarea
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Additional Message"
                ></textarea>
            </div>

            <div className="col-md-12 text-center">
                <SubmitButton label="Register Now !" isSubmitting={isSubmitting} />
            </div>
        </form>
    );
};

export default SignUpForm;
