import TrialForm from '../Forms/TrialForm';
import BaseModal from './BaseModal';

const TrialModal = ({ onRequestClose, show }) => (
    <BaseModal
        subtitle="Please fill out the form below to request a free trial of our software."
        show={show}
        onRequestClose={onRequestClose}
        title="Start Your Free Trial with OTO Today"
    >
        <TrialForm />
    </BaseModal>
);

export default TrialModal;
