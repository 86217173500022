import React from 'react';
import { Link } from 'react-router-dom';

function Navigation() {
    return (
        <ul className="text-right">
            <li>
                <a href="/">Home</a>
            </li>
            <li>
                <Link to="/about">About</Link>
            </li>
            <li>
                <Link to="#">
                    Apps{' '}
                    <span className="menu-expand">
                        <i className="fa fa-angle-down"></i>
                    </span>
                    <ul className="sub-menu">
                        <li>
                            <Link to="/apps/pos">POS</Link>
                        </li>
                        <li>
                            <Link to="/apps/inventory-management">Inventory</Link>
                        </li>
                        <li>
                            <Link to="/apps/backoffice">Backoffice</Link>
                        </li>
                        <li>
                            <Link to="/apps/frontdesk">Frontdesk</Link>
                        </li>
                    </ul>
                </Link>
            </li>
            <li>
                <Link to="/partnership">Partnership</Link>
            </li>
            <li>
                <Link to="/contact">Contact</Link>
            </li>
        </ul>
    );
}

export default Navigation;
